:root {
  --toastify-toast-width: auto;
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #0099ff;
  --toastify-color-success: #1dc9b7;
  --toastify-color-warning: #ffb822;
  --toastify-color-error: #fd397a;
  --toastify-color-progress-light: var(--toastify-color-info);
  --toastify-toast-min-height: 40px;
  --toastify-text-color-light: #6b7888;
  --toastify-font-family: 'Montserrat';
}

/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
  max-width: 560px;
}

/** Classes for the displayed toast **/
.Toastify__toast {
  padding: 8px 16px;
  align-items: center;
  border-radius: 0;
}
.Toastify__toast--rtl {
}
.Toastify__toast-body {
  padding: 0 16px 0 0;
}

/** Used to position the icon **/
.Toastify__toast-icon {
}

/** handle the notificaiton color and the text color based on the theme **/
.Toastify__toast-theme--dark {
}
.Toastify__toast-theme--light {
}
.Toastify__toast-theme--colored.Toastify__toast--default {
}
.Toastify__toast-theme--colored.Toastify__toast--info {
}
.Toastify__toast-theme--colored.Toastify__toast--success {
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
}
.Toastify__toast-theme--colored.Toastify__toast--error {
}

.Toastify__progress-bar {
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar-theme--light {
}
.Toastify__progress-bar-theme--dark {
}
.Toastify__progress-bar--info {
}
.Toastify__progress-bar--success {
}
.Toastify__progress-bar--warning {
}
.Toastify__progress-bar--error {
}
/** colored notifications share the same progress bar color **/
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
  cursor: pointer;
}
.Toastify__close-button--default {
}
.Toastify__close-button > svg {
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
}
